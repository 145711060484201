import React from "react";
import { MdOutlinePayments } from "react-icons/md";


const WaitingPaymentButton = () => {
  return (
    <a href="menunggu-pembayaran">
      <div className="laporan-btn d-flex align-items-center">
        <MdOutlinePayments className="me-2" /> <span>Menunggu Pembayaran</span>
      </div>
    </a>
  );
};

export default WaitingPaymentButton;
