import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import LaporanButton from "./LaporanButton";
import FilterComponent from "./FilterComponent";
import ChipComponent from "./ChipComponent";
import ChipDelivery from "./ChipDelivery";
import { TabPanel, a11yProps } from "./TabPanel";
import CheckboxChooseAll from "./CheckboxChooseAll";
import CardListContent from "./CardListContent";
import NoTransaction from "./NoTransaction";
import { API } from "../configs/API";
import { AppContext } from "../configs/AppContext";
import Pagination from "./Pagination";
import ModalTracking from "./ModalTracking";
import WaitingPaymentButton from "./WaitingPaymentButton";

const MainContentTab = () => {
  const [state, dispatch] = useContext(AppContext);
  const [value, setValue] = useState("all");
  const [paginationData, setPaginationData] = useState({
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 10,
    to: 1,
    total: 0,
  });
  const [cartList, setCartList] = useState();
  const [storeList, setStoreList] = useState();
  const [tracking, setTracking] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCartList([]);
    loadCart(newValue);
  };

  const updateFilter = (keyword, filter, sort, dateStart, dateEnd, cartType) => {
    loadCart(value, keyword, filter, sort, dateStart, dateEnd, cartType);
  };

  const loadCart = async (
    status,
    keyword = "",
    filter = "",
    sort = "",
    dateStart = "",
    dateEnd = "",
    cartType = ""
  ) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });
    setRefresh(false)

    try {
      let url = `cart?status=${status}`;
      if (keyword !== "") url += `&q=${keyword}`;
      if (filter !== "") url += `&filter=${filter}`;
      if (sort !== "") url += `&sort=${sort}`;
      if (dateStart !== "" && dateEnd !== "")
        url += `&date-start=${dateStart}&date-end=${dateEnd}`;
      if (cartType !== "") url += `&cart_type=${cartType}`;

      const res = await API.get(url);

      setCartList(res.data.data);
      setPaginationData(res.data.meta);

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      if (error.response.data) {
        alert(error.response.data.message || "error");
      } else {
        alert(error);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    }
  };

  const loadStoreList = async () => {
    const res = await API.get("store-list");
    setStoreList(res.data.data);
  };

  useEffect(() => {
    loadStoreList();
    loadCart(value);
  }, []);

  const onTrackClicked = (data) => {
    if (!data) return setNullTracking();
    setTracking(data);
  };

  const setNullTracking = () => {
    setTracking(null);
  };

  return (
    <Container fluid className="mt-4 ms-4">
      <div className="d-flex justify-content-between align-items-center">
        <div className="main-tab-header mb-2">Daftar Pesanan</div>
        <div className="d-flex justify-content-center align-items-center gap-2">
          <WaitingPaymentButton />
          <LaporanButton />
        </div>
      </div>

      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: "0px 4px 20px 0 rgba(215, 217, 226, 0.46)",
        }}
        className="mb-3"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            label="Semua Pesanan"
            {...a11yProps(0)}
            value={"all"}
            className="main-single-tab"
          />
          <Tab
            label="Pesanan Baru"
            {...a11yProps(8)}
            className="main-single-tab"
            value={"new"}
          />
          <Tab
            label="Siap Dikirim"
            {...a11yProps(9)}
            className="main-single-tab"
            value={"ready-to-send"}
          />
          <Tab
            label="Dalam Pengiriman"
            {...a11yProps(10)}
            className="main-single-tab"
            value={"on-delivery"}
          />
          <Tab
            label="Pesanan Selesai"
            {...a11yProps(11)}
            className="main-single-tab"
            value={"complete"}
          />
          <Tab
            label="Dibatalkan"
            {...a11yProps(7)}
            className="main-single-tab"
            value={"rejected"}
          />
        </Tabs>

        <TabPanel value={value} index={"all"} className="main-tab-panel">
          <FilterComponent refresh={refresh} updateFilter={updateFilter} />
        </TabPanel>
        <TabPanel value={value} index={"new"} className="main-tab-panel">
          {/* <ChipComponent loadCart={loadCart} /> */}
          <FilterComponent refresh={refresh} updateFilter={updateFilter} />
        </TabPanel>
        <TabPanel
          value={value}
          index={"ready-to-send"}
          className="main-tab-panel"
        >
          <ChipDelivery loadCart={loadCart} />
          <FilterComponent refresh={refresh} updateFilter={updateFilter} />
        </TabPanel>
        <TabPanel
          value={value}
          index={"on-delivery"}
          className="main-tab-panel"
        >
          <FilterComponent refresh={refresh} updateFilter={updateFilter} />
        </TabPanel>
        <TabPanel value={value} index={"complete"} className="main-tab-panel">
          <FilterComponent refresh={refresh} updateFilter={updateFilter} />
        </TabPanel>
        <TabPanel value={value} index={"rejected"} className="main-tab-panel">
          <FilterComponent refresh={refresh} updateFilter={updateFilter} />
        </TabPanel>
      </Box>

      {value <= 12 && <CheckboxChooseAll />}

      <div>
        {value !== 99 && (
          <>
            {cartList &&
              cartList.map((cart) => (
                <CardListContent
                  key={cart.id}
                  cartData={cart}
                  onUpdate={() => setRefresh(true)}
                  storeList={storeList}
                  onTrackClicked={onTrackClicked}
                />
              ))}
          </>
        )}
        {/*{value === 1 && <CardListContent />}*/}
        {/*{value === 2 && <CardListDelivery />}*/}
        {/*{value === 3 && <CardListOnDelivery />}*/}
        {value === 99 && <NoTransaction />}
      </div>

      <Pagination data={paginationData} />

      <ModalTracking
        data={tracking}
        open={tracking != null}
        onClose={setNullTracking}
      ></ModalTracking>
    </Container>
  );
};

export default MainContentTab;
