import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import NavbarComponent from "../components/NavbarComponent";
import { APP_VERSION } from "../configs/App";
import WaitingPaymentContent from "../components/WaitingPaymentContent";

const WaitingPayment = () => {
  return (
    <Container fluid className="app-main-container" style={{ padding: 0 }}>
      <Row style={{ height: "55px" }}>
        <Col>
          <NavbarComponent />
        </Col>
      </Row>

      <Row>
        <Col>
          <Container fluid className="d-flex" style={{ padding: 0 }}>
            <WaitingPaymentContent/>
          </Container>
        </Col>
        <div className="small py-2 px-4 " style={{ textAlign: 'right' }}>
          v{APP_VERSION} &#169;{new Date().getFullYear()} PT Global Sukses Digital
        </div>
      </Row>
    </Container>
  );
};

export default WaitingPayment;
