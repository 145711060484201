import React from "react";
import { FaShoppingCart } from "react-icons/fa";



const OrderButton = () => {
  return (
    <a href="/order">
      <div className="laporan-btn d-flex align-items-center">
        <FaShoppingCart className="me-2" /> <span>Semua Pesanan</span>
      </div>
    </a>
  );
};

export default OrderButton;
